import React from "react";

function EmptyPage() {
  return (
    <div>
      <h1>Please enter a valid professor name</h1>
    </div>
  );
}

export default EmptyPage;
